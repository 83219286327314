body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.danielaSebastian {
    max-width: 100%;
}

.sigContainer {
    width: 80%;
    height: 80%;
    margin: 0 auto;
    background-color: #fff;
}

.sigPad {
    width: 100%;
    height: 100%;
    border: 1px solid #adadad;
    border-radius: 4px;
}


/* HIDE RADIO */
[type=radio] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

/* IMAGE STYLES */
[type=radio] + img {
    cursor: pointer;
    border: 1px solid #adadad;
}

/* CHECKED STYLES */
[type=radio]:checked + img {
    outline: 4px solid #cd1719;;
}

.thumbnails + img {

}